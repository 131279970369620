import { createI18n } from 'vue-i18n';

import nl from './nl';

// i18n translations
export const i18n = createI18n({
	locale: 'nl',
	fallbackLocale: 'nl',
	messages: {
    nl,
  },
	warnHtmlInMessage: 'off',
});

export const t = i18n.global.t;
