import { ref, computed } from 'vue';
import { State } from '@/utils/types/Modal';
import { getCampaign } from '@/utils/helpers/campaign';

const initialState = (): State => ({
  show: false,
  content: null,
});

const state = ref<State>(initialState());

export default () => {
  const reset = () => {
    state.value = initialState();
  };

  const showModal = (content) => {
    const campaign = getCampaign();

    if (campaign) {
      state.value.show = true;
      state.value.content = content;
    } else {
      window.open(content);
    }
  };

  const hideModal = () => {
    reset();
  };

  return {
    showModal,
    hideModal,
    show: computed(() => state.value.show),
    content: computed(() => state.value.content),
  };
};
