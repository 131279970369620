export default {
  // Start
  start_title: 'Voel je jou ook opgewekt door de zon?',
  start_description: 'Hoe en met welke combinatie van slimme energietechnieken realiseer je de grootste besparing? Wat is de terugverdientijd van jouw zonnepanelen? Bereken via deze tool je rendement en een richtprijs.',
  start_cta_link_text: 'Bekijk onze energieoplossingen',
  start_cta_link: '/overzicht_productpagina.pdf',
  start_cta_primary: 'Bereken je potentiële rendement',

  // Simulatie
  simulation_sidebar_copyright: '© Linea Trovata -',
  simulation_sidebar_terms_conditions_link: 'images/start_background.jpeg',
  simulation_question_label: 'Vraag', // Zoals in "Vraag 1/3"
  simulation_form_next_button: 'Volgende vraag',
  simulation_form_previous_button: 'Vorige vraag',
  simulation_form_submit_button: 'Bekijk mijn offerte',

  // Stapgroepen
    // Mijn woning
  step_group_my_home_title: 'Mijn woning',
  step_group_my_home_description: 'Tijdens deze stap stellen we je enkele praktische vragen over jouw woning. Ben je niet zeker van een vraag schakel gerust de hulp in van één van onze collega’s.',
    // Mijn installatie
  step_group_my_setup_title: 'Mijn installatie',
  step_group_my_setup_description: 'Tijdens deze ronde stellen we je enkele vragen over de installatie van de zonnepanelen. Ben je niet zeker van een vraag schakel gerust de hulp in van één van onze collega’s.',
    // Mijn offerte
  step_group_my_quote_title: 'Mijn offerte',
  step_group_my_quote_description: 'Laat je gegevens na om je indicatieve offerte te raadplegen. Je ontvangt een mail van ons met bijkomende informatie over je configuratie.',

  // Stappen
    // Verbruik
  step_usage_title: 'Hoeveel kilowattuur (kWh) verbruik je op jaarbasis?',
  step_usage_eenheid_label: ' kWh',
  step_usage_slider_help: 'Heb je geen idee wat jouw jaarlijks verbruik is? Dan raden we aan om 4000 kWh als referentiepunt te nemen.',
    // Type dak
  step_roof_title: 'Welk type dakbedekking heeft jouw woning?',
  step_roof_pannen_label: 'Pannen',
  step_roof_leien_label: 'Leien',
  step_roof_tegelpannen_label: 'Tegelpannen',
  step_roof_platdak_label: 'Plat dak',
  step_roof_ander_label: 'Ander',
    // Hoogte gebouw
  step_height_title: 'Wat is de hoogte van jouw woning?',
  step_height_1e_verdiep_label: '1e verdieping',
  step_height_2e_verdiep_label: '2e verdieping',
  step_height_3e_verdiep_label: '3e verdieping',
    // Orientatie gebouw
  step_orientation_title: 'Wat is de orientatie van jouw dak?',
  step_orientation_zuid_label: 'Zuid gericht',
  step_orientation_oost_label: 'Oost gericht',
  step_orientation_west_label: 'West gericht',
  step_orientation_zuidwest_label: 'Zuidwest gericht',
    // Type bouw
  step_build_title: 'Is jouw woning nieuwbouw of een bestaande woning?',
  step_build_nieuwbouw_label: 'Nieuwbouw',
  step_build_nieuwbouw_result_label: 'Ja',
  step_build_bestaand_label: 'Bestaande woning',
  step_build_bestaand_result_label: 'Nee',
  // Aansluiting
  step_connection_title: 'Wat is de aansluiting van jouw woning?',
  step_connection_3_fasig_label: 'Driefase aansluiting',
  step_connection_3_fasig_result_label: 'Driefase',
  step_connection_mono_label: 'Monofase aansluiting',
  step_connection_mono_result_label: 'Monofase',
  step_connection_unknown_label: 'Weet ik niet',
    // BTW (Ouder dan 10 jaar)
  step_vat_title: 'Is jouw woning ouder dan 10 jaar?',
  step_vat_yes_label: 'Ja, 6% BTW',
  step_vat_no_label: 'Nee, 21% BTW',
    // Type zonnepaneel
  step_solar_panel_title: 'Wat voor type zonnepanelen wil je?',
  step_solar_panel_mono_label: '430Wp full black',
  step_solar_panel_mono_result_label: '430Wp full black',
  step_solar_panel_polycrystalline_label: '435 Wattpiek',
  step_solar_panel_polycrystalline_result_label: '435 Wattpiek',
    // Batterijpakket
  step_battery_pack_title: 'Heb je interesse in een bijhorende thuisbatterij? ',
  step_battery_pack_yes_label: 'Ja, graag',
  step_battery_pack_no_label: 'Nee, bedankt',
    // Laadpaal
  step_charger_title: 'Heb je interesse in een laadpaal?',
  step_charger_yes_label: 'Ja, graag',
  step_charger_no_label: 'Nee, bedankt',
    // Gegevens
  step_info_title: 'Mijn gegevens',
  step_info_form_title: 'Jouw gegevens',
  step_info_form_description: 'Om jou te kunnen contacteren indien je gewonnen bent, hebben we enkele gegevens van jou nodig. Leg hier verder uit waarom je de gegevens opvraagt en wat je ermee zal doen.',
  step_info_email_label: 'Jouw e-mailadres *',
  step_info_email_placeholder: 'Jouw e-mailadres',
  step_info_first_name_label: 'Voornaam *',
  step_info_first_name_placeholder: 'Jouw voornaam',
  step_info_last_name_label: 'Naam *',
  step_info_last_name_placeholder: 'Jouw naam',
  step_info_street_label: 'Straat *',
  step_info_street_placeholder: 'Jouw straat',
  step_info_streetnumber_label: 'Nummer *',
  step_info_streetnumber_placeholder: 'Huisnr.',
  step_info_city_label: 'Woonplaats *',
  step_info_city_placeholder: 'Jouw woonplaats',
  step_info_cityzip_label: 'Postcode *',
  step_info_cityzip_placeholder: 'Postcode',
  step_info_country_label: 'Land *',
  step_info_country_placeholder: 'België',
  step_info_country_description: 'Momenteel zijn enkel adressen binnen België mogelijk',
  step_info_phone_label: 'Gsm-nummer *',
  step_info_phone_placeholder: 'Jouw gsm-nummer',
  step_info_newsletter_label: 'Ik wens de nieuwsbrief van Linea Trovata te ontvangen',
  step_info_terms_conditions_label: 'Ik ga akkoord met de',
  step_info_terms_conditions_label_link_text: 'algemene voorwaarden',
  step_info_terms_conditions_label_link: 'images/start_background.jpeg',
  step_info_comments_label: 'Opmerkingen',
    // Berekening resultaat
  result_title: 'Indicatieve prijsberekening',
      // Oplijsting keuzes
  result_title_home: 'Details woning',
  result_title_installation: 'Details installatie',
  result_usage_label: 'Geschat verbruik:',
  result_roof_label: 'Type dakbedekking:',
  result_height_label: 'Hoogte gebouw:',
  result_orientation_label: 'Orientatie dak:',
  result_build_label: 'Nieuwbouw:',
  result_connection_label: 'Aansluiting woning:',
  result_vat_label: 'Ouder dan 10 jaar:',
  result_solar_panel_label: 'Type zonnepaneel:',
  result_battery_pack_label: 'Optie batterijpakket:',
  result_charger_label: 'Optie laadpaal:',
  result_reference_label: 'Referentie:',
  result_yes_label: 'Ja',
  result_no_label: 'Nee',
      // Berekening
  result_total_label: 'Geschatte totaalprijs',
  result_total_vat: 'Inclusief {vat} BTW',
  result_total_comment: '* De totaalprijs is indicatief. Een totaalprijs kan pas opgemaakt worden na een bezoek door één van onze specialisten',
  result_time_label: 'Terugverdientijd',
  result_time_year: 'Jaar',
  result_time_text: 'Na reeds {amount} jaar is je investering teruggewonnen.',
  result_new_simulation_button: 'Maak een nieuwe simulatie',
  result_tab_overview: 'Samenvatting',
  result_tab_detail: 'Detailberekening',

  result_detail_your_investment_label: 'Jouw investering',
  result_detail_solar_panels_label: 'Zonnepanelen (bedrag incl. BTW)',
  result_detail_charger_label: 'Laadpaal (bedrag incl. BTW)',
  result_detail_battery_pack_label: 'Batterijsysteem (bedrag incl. BTW)',

  result_detail_bonuses_label: 'BIS-actie',
  result_detail_bonus_solar_panels_label: 'BIS-actie aftrek zonnepanelen',
  result_detail_bonus_battery_pack_label: 'BIS-actie aftrek thuisbatterij',

  result_detail_roi_time_label: 'Terugverdientijd',
  result_detail_roi_label: 'Geschatte terugverdientijd zonnepanelen met digitale meter',

  result_detail_total_label: 'Geschatte totaalprijs (incl. premies en BTW)',


  // Error berichten voor formulieren
  form_field_required: 'Dit veld is verplicht.',

  // Toast berichten
  toast_almost_inactive: 'De calculator zal zich binnenkort resetten. Tap op het scherm om verder te gaan.',
  toast_reset_inactive: 'De calculator was hersteld na 3 minuten geen activiteit.',
  toast_reset_success: 'De calculator werd met succes gestopt.',
  toast_api_save_error: 'De data werd niet opgeslagen op de server. Data wordt nu lokaal opgeslagen ..',
  toast_api_pricing_error: 'De calculator kon niet worden gestart. Kijk je connectie na of probeer opnieuw',
};
