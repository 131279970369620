import { ref, computed } from 'vue';
import { State } from '@/utils/types/Pricing';
import { restFetch } from '@/utils/helpers/api';
import useSimulation from '@/modules/simulation/composables/useSimulation';

const initialState = (): State => ({
  pricing: null,
});

const state = ref<State>(initialState());

export default () => {
  const reset = () => {
    state.value = initialState();
  };

  const fetch = async () => {
    const data = await restFetch({
      method: 'GET',
      url: '/pricing',
    });

    state.value.pricing = data;
  };

  const parsePrice = (price) => {
    return parseFloat(price.replace('.', '').replace(',', '.'));
  };

  const calculate = () => {
    const { formData } = useSimulation();

    const panels = state.value.pricing.panels;
    const roofTypes = state.value.pricing.roofing;
    const otherData = state.value.pricing.other;
    const valueUsage = formData.value['yearly_kw'];
    const valuePanelType = formData.value['panel_type'];
    const valueRoofType = formData.value['type_roofing'];
    const valueHeight = formData.value['building_height'];
    const valueBatteryPack = formData.value['battery_pack'];
    const valueCharger = formData.value['loading_dock'];
    const valueVat = formData.value['house_age'] === 'yes' ? 0.06 : 0.21;

    const pricingPanel = panels.find((panel) => {
      return panel.panel_type === valuePanelType && panel.max_range_kwh >= valueUsage && panel.min_range_kwh <= valueUsage;
    });

    const pricingRoofType = roofTypes.find((roof) => {
      if (valueRoofType !== 'platdak' && valueRoofType !== 'leien') return { price: 0 };

      const roofType = valueRoofType === 'platdak' ? 'flat' : 'slated';
      return roof.number_of_panels === pricingPanel.number_of_panels && roof.panel_type === pricingPanel.panel_type && roofType === roof.roofing_type;
    });

    const pricingHeight = otherData.find(item => item.key === `height_building_${valueHeight}_floor${valueHeight !== '1' ? 's' : ''}`);
    const pricingBatteryPack = otherData.find(item => item.key === `battery_pack_${valueBatteryPack}`);
    const pricingCharger = otherData.find(item => item.key === `loading_dock_${valueCharger}`);

    const prices = [pricingPanel, pricingRoofType, pricingHeight, pricingBatteryPack, pricingCharger];

    const totalPriceWithoutVat = prices.reduce((acc, priceItem) => priceItem ? acc + parsePrice(priceItem.price) : acc, 0);
    const totalPriceWithVat = totalPriceWithoutVat + (totalPriceWithoutVat * valueVat);

    // Calculation of the amount of years to get back your investment
    const batteryBonus = valueBatteryPack === 'yes' ? 0 : 0;
    const yieldPerYear = valueBatteryPack === 'no' ? pricingPanel.yearly_yield_without_batteries : pricingPanel.yearly_yield_with_batteries;
    const panelPrice = totalPriceWithoutVat - parsePrice(pricingPanel.payback_panel) - batteryBonus;
    const roiYears = (panelPrice + (panelPrice * valueVat)) / parsePrice(yieldPerYear);

    return {
      total: totalPriceWithVat,
      totalWithBonuses: totalPriceWithVat - (batteryBonus + parsePrice(pricingPanel.payback_panel)),
      returnTime: roiYears,
      numberOfPanels: pricingPanel.number_of_panels,
      pricePanels: parsePrice(pricingPanel.price),
      batteryPrice: parsePrice(pricingBatteryPack.price),
      chargerPrice: parsePrice(pricingCharger.price),
      batteryPriceWithVat: parsePrice(pricingBatteryPack.price) + (parsePrice(pricingBatteryPack.price) * valueVat),
      chargerPriceWithVat: parsePrice(pricingCharger.price) + (parsePrice(pricingCharger.price) * valueVat),
      panelBonus: parsePrice(pricingPanel.payback_panel),
      batteryBonus: batteryBonus,
      totalPanelPrice: parsePrice(pricingPanel.price) + parsePrice(pricingRoofType.price) + parsePrice(pricingHeight.price) + ((parsePrice(pricingPanel.price) + parsePrice(pricingRoofType.price) + parsePrice(pricingHeight.price)) * valueVat),
    };
  };

  return {
    reset,
    fetch,
    calculate,
    pricing: computed(() => state.value.pricing),
  };
};
