
  import { ref } from 'vue';
  import { useToast } from "vue-toastification";
  import useSimulation from '@/modules/simulation/composables/useSimulation';
  import usePricing from '@/modules/pricing/composables/usePricing';
  import useModal from '@/modules/modal/composables/useModal';
  import { t } from '@/utils/languages';
  import Logo from '@/components/Logo.vue';

  export default {
    components: {
      Logo,
    },
    setup() {
      const loading = ref(false);
      const { start: startSimulation } = useSimulation();
      const { fetch: fetchPricing } = usePricing();
      const { showModal } = useModal();

      const slideAmount = ref(10);
      const activeSlide = ref(1);

      const rotateSlides = () => {
        setTimeout(() => {
          activeSlide.value = activeSlide.value === slideAmount.value ? 1 : activeSlide.value + 1;
          rotateSlides();
        }, 4000);
      };

      const start = async () => {
        try {
          loading.value = true;
          await fetchPricing();
          startSimulation();
          window.scrollTo(0, 0);
          loading.value = false;
        } catch (error) {
          const toast = useToast();
          toast.error(t('toast_api_pricing_error'));
          loading.value = false;
        }
      };

      rotateSlides();

      return {
        start,
        loading,
        showModal,
        activeSlide,
        slideAmount,
      };
    },
  };
