import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full w-full py-6 flex flex-col lg:justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TileSelect = _resolveComponent("TileSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TileSelect, {
      name: $props.step.id,
      options: $props.step.content.payload.options,
      onSelect: $setup.incrementStep,
      onSelecting: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('selecting', $event)))
    }, null, 8, ["name", "options", "onSelect"])
  ]))
}