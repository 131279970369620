
  import useSimulation from '@/modules/simulation/composables/useSimulation';
  import Logo from '@/components/Logo.vue';

  export default {
    components: {
      Logo,
    },
    props: {
      title: {
        type: [String, Boolean],
        default: false,
      },
    },
    emits: ['logo-click'],
    setup() {
      const { groups, currentGroup, currentGroupSteps, currentStep, currentGroupStepIndex } = useSimulation();

      return {
        groups,
        currentGroup,
        currentGroupSteps,
        currentStep,
        currentGroupStepIndex,
      };
    },
  };
