<template>
  <SimulationWrapper :title="$t('result_title')">
    <SimulationResult />
  </SimulationWrapper>
</template>

<script>
  import SimulationResult from '@/modules/simulation/components/SimulationResult.vue';
  import SimulationWrapper from '@/modules/simulation/components/SimulationWrapper.vue';

  export default {
    components: {
      SimulationResult,
      SimulationWrapper,
    },
  };
</script>
