import { StepGroup } from '@/utils/types/StepGroup';
import { t } from '@/utils/languages';

const stepGroups: StepGroup[] = [{
  id: 'my-home',
  title: t('step_group_my_home_title'),
  description: t('step_group_my_home_description'),
}, {
  id: 'my-setup',
  title: t('step_group_my_setup_title'),
  description: t('step_group_my_setup_description'),
}, {
  id: 'my-quote',
  title: t('step_group_my_quote_title'),
  description: t('step_group_my_quote_description'),
}];

export default stepGroups;
