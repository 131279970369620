import { getCampaign } from '@/utils/helpers/campaign';

export const storeDataLocally = (id: string, data: FormData) => {
  const storedData = localStorage.getItem('storedData');
  const formData = storedData ? JSON.parse(storedData) : [];

  if (formData.some(entry => entry.id === id)) return;

  const newEntry = {
    id,
    _meta: {
      created: new Date(),
      campaign: getCampaign(),
    },
  };

  for (const entry of data.entries()) {
    newEntry[entry[0]] = entry[1];
  }

  formData.push(newEntry);

  localStorage.setItem('storedData', JSON.stringify(formData));
};
