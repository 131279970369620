<template>
  <div v-if="show" class="fixed z-50 h-screen w-screen bg-dark bg-opacity-30 p-6">
    <div class="bg-white relative h-full w-full">
      <div class="absolute right-6 top-6 cursor-pointer" @click="hideModal">
        <img class="w-16 h-16" src="@/assets/images/icons/close.svg" alt="Close button" />
      </div>
      <div class="h-full w-full overflow-y-scroll">
        <vue-pdf-embed v-if="content" class="w-full block" :source="content" />
      </div>
    </div>
  </div>
</template>

<script>
  import VuePdfEmbed from 'vue-pdf-embed';
  import useModal from '@/modules/modal/composables/useModal';

  export default {
    components: {
      VuePdfEmbed,
    },
    setup() {
      const { show, content, hideModal } = useModal();

      return {
        show,
        content,
        hideModal,
      };
    },
  };
</script>
