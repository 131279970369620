import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimulationForm = _resolveComponent("SimulationForm")!
  const _component_SimulationWrapper = _resolveComponent("SimulationWrapper")!

  return (_openBlock(), _createBlock(_component_SimulationWrapper, { onLogoClick: $setup.handleLogoClick }, {
    default: _withCtx(() => [
      _createVNode(_component_SimulationForm, { class: "h-full" })
    ]),
    _: 1
  }, 8, ["onLogoClick"]))
}