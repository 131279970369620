import { ref, computed } from 'vue';
import crypto from 'crypto';
import { State } from '@/utils/types/Simulation';
import { restFetch } from '@/utils/helpers/api';
import { t } from '@/utils/languages';

import steps from '@/utils/config/steps';
import stepGroups from '@/utils/config/stepGroups';
import { storeDataLocally } from '@/utils/helpers/simulationStorage';
import { getCampaign } from '@/utils/helpers/campaign';

const initialState = (): State => ({
  started: false,
  ended: false,
  currentStep: 0,
  currentGroup: 0,
  steps: steps,
  groups: stepGroups,
  form: {
    id: null,
    data: null,
  },
  result: null,
});

const state = ref<State>(initialState());

export default () => {
  const reset = () => {
    state.value = initialState();
  };

  const start = () => {
    const campaign = getCampaign();
    state.value.form.id = `${campaign ? campaign.toUpperCase() : 'WEB'}${crypto.randomBytes(2).toString('hex').toUpperCase()}`;

    if (!state.value.form.data) {
      state.value.form.data = state.value.steps.reduce((acc, step) => {
        acc[step.id] = step.value || null;

        return acc;
      }, {});
    }

    state.value.started = true;
  };

  const getStepLabel = (stepId) => {
    const step = state.value.steps.find(step => step.id === stepId);

    if (!step) return;
    if (!step.content || !step.content.payload || !step.content.payload.options) return;

    const option = step.content.payload.options.find(option => option.value === state.value.form.data[stepId]);

    if (!option) return;

    if (option.value === 'yes') return t('result_yes_label');
    if (option.value === 'no') return t('result_no_label');

    return option.resultLabel || option.label;
  };

  const storeSimulationData = async () => {
    const bodyFormData = new FormData();

    if (!state.value.form.id) return;

    bodyFormData.append('reference', state.value.form.id);
    bodyFormData.append('yearly_kw', state.value.form.data.yearly_kw);
    bodyFormData.append('type_roofing', getStepLabel('type_roofing'));
    bodyFormData.append('number_of_panels', state.value.result.numberOfPanels);
    bodyFormData.append('building_height', getStepLabel('building_height'));
    bodyFormData.append('roof_orientation', getStepLabel('roof_orientation'));
    bodyFormData.append('house_type_age', getStepLabel('house_type_age'));
    bodyFormData.append('connection', getStepLabel('connection'));
    bodyFormData.append('house_age', getStepLabel('house_age'));
    bodyFormData.append('panel_type', getStepLabel('panel_type'));
    bodyFormData.append('battery_pack', getStepLabel('battery_pack'));
    bodyFormData.append('loading_dock', getStepLabel('loading_dock'));
    bodyFormData.append('email', state.value.form.data.email);
    bodyFormData.append('first_name', state.value.form.data.first_name);
    bodyFormData.append('last_name', state.value.form.data.last_name);
    bodyFormData.append('street', state.value.form.data.street);
    bodyFormData.append('house_number', state.value.form.data.house_number);
    bodyFormData.append('zip', state.value.form.data.zip);
    bodyFormData.append('city', state.value.form.data.city);
    bodyFormData.append('country', 'België');
    bodyFormData.append('phone', state.value.form.data.phone);
    bodyFormData.append('comments', state.value.form.data.comments);
    bodyFormData.append('terms_and_conditions', state.value.form.data.terms_and_conditions !== 'undefined' && state.value.form.data.terms_and_conditions !== 'false' ? '1' : '0');
    bodyFormData.append('newsletter', state.value.form.data.newsletter !== 'undefined' && state.value.form.data.newsletter !== 'false' ? '1' : '0');
    bodyFormData.append('price_total', state.value.result.total);
    bodyFormData.append('payback_period', state.value.result.returnTime);
    bodyFormData.append('deduction_batteries', state.value.result.batteryBonus);
    bodyFormData.append('deduction_solar_panels', state.value.result.panelBonus);
    bodyFormData.append('price_battery', state.value.result.batteryPrice);
    bodyFormData.append('price_loading_dock', state.value.result.chargerPrice);
    bodyFormData.append('price_solar_panels', state.value.result.pricePanels);
    bodyFormData.append('price_battery_vat', state.value.result.batteryPriceWithVat);
    bodyFormData.append('price_loading_dock_vat', state.value.result.chargerPriceWithVat);
    bodyFormData.append('price_solar_panels_total', state.value.result.totalPanelPrice);

    storeDataLocally(state.value.form.id, bodyFormData);

    const campaign = getCampaign();

    await restFetch({
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json',
      },
      url: campaign ? `/submission?src=${campaign}` : '/submission',
      data: bodyFormData,
    });
  };

  const started = computed(() => state.value.started);
  const steps = computed(() => state.value.steps);
  const groups = computed(() => state.value.groups);
  const currentGroup = computed(() => state.value.groups[state.value.currentGroup]);
  const currentStep = computed(() => state.value.steps[state.value.currentStep]);
  const currentGroupSteps = computed(() => state.value.steps.filter(step => step.group === currentGroup.value.id));
  const currentGroupStepIndex = computed(() => currentGroupSteps.value.findIndex(step => step.id === currentStep.value.id));
  const currentStepIndex = computed(() => steps.value.findIndex(step => step.id === currentStep.value.id));
  const isFirstStep = computed(() => currentStepIndex.value === 0);
  const isLastStep = computed(() => currentStepIndex.value === steps.value.length - 1);
  const formId = computed(() => state.value.form.id);

  const ended = computed({
    get: () => state.value.ended,
    set: (v) => state.value.ended = v,
  });

  const result = computed({
    get: () => state.value.result,
    set: (v) => state.value.result = v,
  });

  const formData = computed({
    get: () => state.value.form.data,
    set: (v) => state.value.form.data = v,
  });

  const incrementStep = () => {
    if (currentGroupStepIndex.value === currentGroupSteps.value.length - 1) state.value.currentGroup += 1;
    state.value.currentStep += 1;
    window.scrollTo(0, 0);
  };

  const decrementStep = () => {
    if (currentGroupStepIndex.value === 0) state.value.currentGroup -= 1;
    state.value.currentStep -= 1;
  };

  return {
    reset,
    start,
    result,
    started,
    ended,
    groups,
    steps,
    currentGroup,
    currentStep,
    currentGroupStepIndex,
    currentStepIndex,
    currentGroupSteps,
    isFirstStep,
    isLastStep,
    formData,
    formId,
    incrementStep,
    decrementStep,
    storeSimulationData,
    getStepLabel,
  };
};
