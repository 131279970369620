import axios, { AxiosError, AxiosResponse } from "axios";
import { ValidationErrorsResponse } from "@/utils/types/Api";

export const initializeAxios = () => {
  axios.defaults.baseURL = `${process.env.VUE_APP_API_BASE_URL}`;
};

export const restFetch = <T>({ data = {}, params = {}, url, headers = {}, method = 'POST' }: {
  data?: Record<string, any>;
  params?: Record<string, any>;
  url: string;
  headers?: any;
  method?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';
}): Promise<T | ValidationErrorsResponse> => {
  console.log(axios.defaults.baseURL+url);

  return new Promise((resolve, reject) => {
    return axios({
      method,
      url,
      data,
      headers,
      params,
    })
      .then((response: AxiosResponse<T>) => {
        return resolve(response.data);
      })
      .catch((error: AxiosError<ValidationErrorsResponse>) => {
        return reject({
          errors: error.response?.data.errors,
          message: error.response?.data.message,
        });
      })
      ;
  });
};
