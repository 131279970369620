
  import { PropType } from 'vue';
  import { Step } from '@/utils/types/Step';

  import TileSelect from '@/components/TileSelect.vue';
  import useSimulation from '@/modules/simulation/composables/useSimulation';

  export default {
    props: {
      step: {
        type: Object as PropType<Step>,
        required: true,
      },
    },
    components: {
      TileSelect,
    },
    emits: ['selecting'],
    setup() {
      const { incrementStep } = useSimulation();

      return {
        incrementStep,
      };
    },
  };
