
  import { ref } from 'vue';
  import { useToast } from "vue-toastification";
  import SimulationForm from '@/modules/simulation/components/SimulationForm.vue';
  import SimulationWrapper from '@/modules/simulation/components/SimulationWrapper.vue';
  import useSimulation from '@/modules/simulation/composables/useSimulation';
  import { t } from '@/utils/languages';

  export default {
    components: {
      SimulationForm,
      SimulationWrapper,
    },
    setup() {
      const toast = useToast();
      const { started, reset } = useSimulation();

      // Functionality to reset the simulation when the logo is clicked three times in rapid succession
      const logoClicked = ref(0);
      let logoClickTimeout: ReturnType<typeof setTimeout>;

      const resetLogoClicks = () => {
        logoClicked.value = 0;
      };

      const handleLogoClick = () => {
        if (started.value) {
          logoClicked.value += 1;

          if (logoClickTimeout) clearTimeout(logoClickTimeout);

          if (logoClicked.value === 3) {
            reset();
            logoClicked.value = 0;
            toast.success(t('toast_reset_success'));
          } else {
            logoClickTimeout = setTimeout(resetLogoClicks, 1000);
          }
        }
      };

      return {
        handleLogoClick,
      };
    },
  };
