import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "-m-3" }
const _hoisted_2 = { class: "flex flex-wrap justify-center w-full" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["name", "value"]
const _hoisted_5 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option, index) => {
        return (_openBlock(), _createBlock(_component_Field, {
          key: index,
          name: $props.name,
          type: "radio",
          "validate-on-mount": "",
          value: false
        }, {
          default: _withCtx(({ field, value }) => [
            _createElementVNode("div", {
              class: _normalizeClass(["p-3", {
            'w-full sm:w-1/2 md:w-1/3': $props.itemsPerRow === 3,
            'w-full sm:w-1/2': $props.itemsPerRow === 2,
          }])
            }, [
              _createElementVNode("label", {
                class: _normalizeClass(["cursor-pointer rounded-xl block h-full", {
              'opacity-60 hover:opacity-100': value !== option.value && value,
              'shadow-lg': value === option.value
            }]),
                onClick: ($event: any) => ($setup.selectValue(option.value))
              }, [
                _createElementVNode("input", _mergeProps({
                  class: "hidden",
                  type: "radio",
                  name: $props.name
                }, field, {
                  value: option.value
                }), null, 16, _hoisted_4),
                (option.image || option.icon)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["relative w-full rounded-t-xl overflow-hidden h-28", [{
                'border border-lightgray': option.icon,
                'border-b-transparent': option.icon && value === option.value,
              }, option.mediaWrapperSizeClass]])
                    }, [
                      _createElementVNode("img", {
                        class: _normalizeClass(["absolute", [{
                  'w-full h-full object-cover': option.image,
                  'left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 h-14 w-14 object-cover': option.icon,
                }, option.mediaSizeClass]]),
                        src: option.image || option.icon,
                        alt: option.label
                      }, null, 10, _hoisted_5)
                    ], 2))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: _normalizeClass(["border border-t-0 rounded-b-xl font-medium text-base p-4 text-center", {
                'border-lightgray': value !== option.value,
                'border-primary bg-primary': value === option.value,
                'border-t rounded-xl h-full flex items-center justify-center': !option.icon && !option.image
              }])
                }, _toDisplayString(option.label), 3)
              ], 10, _hoisted_3)
            ], 2)
          ]),
          _: 2
        }, 1032, ["name"]))
      }), 128))
    ])
  ]))
}