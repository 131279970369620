
  import { PropType } from 'vue';
  import FormItem from "@/components/FormItem.vue";
  import { Step } from '@/utils/types/Step';
  import useModal from '@/modules/modal/composables/useModal';

  export default {
    props: {
      step: {
        type: Object as PropType<Step>,
        required: true,
      },
    },
    components: {
      FormItem,
    },
    setup() {
      const { showModal } = useModal();

      return {
        showModal,
      };
    },
  };
