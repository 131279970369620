import { shallowRef } from 'vue';
import { Step } from '@/utils/types/Step';
import { t } from '@/utils/languages';
import * as yup from 'yup';

import StepUsage from '@/modules/simulation/components/steps/StepUsage.vue';
import StepRoof from '@/modules/simulation/components/steps/StepRoof.vue';
import StepHeight from '@/modules/simulation/components/steps/StepHeight.vue';
import StepOrientation from '@/modules/simulation/components/steps/StepOrientation.vue';
import StepBuild from '@/modules/simulation/components/steps/StepBuild.vue';
import StepConnection from '@/modules/simulation/components/steps/StepConnection.vue';
import StepVat from '@/modules/simulation/components/steps/StepVat.vue';
import StepSolarPanel from '@/modules/simulation/components/steps/StepSolarPanel.vue';
import StepBatteryPack from '@/modules/simulation/components/steps/StepBatteryPack.vue';
import StepCharger from '@/modules/simulation/components/steps/StepCharger.vue';
import StepInfo from '@/modules/simulation/components/steps/StepInfo.vue';

const steps: Step[] = [{
  id: 'yearly_kw',
  group: 'my-home',
  title: t('step_usage_title'),
  icon: '@/assets/icons/lightning.svg',
  value: 4000,
  content: {
    component: shallowRef(StepUsage),
    payload: {
      slider: {
        min: 800,
        max: 14000,
        step: 100,
      },
    },
    validationSchema: yup.object({
      yearly_kw: yup.number().required(t('form_field_required')),
    }),
  },
}, {
  id: 'type_roofing',
  group: 'my-home',
  title: t('step_roof_title'),
  icon: '@/assets/icons/lightning.svg',
  content: {
    component: shallowRef(StepRoof),
    payload: {
      options: [{
        value: 'pannen',
        label: t('step_roof_pannen_label'),
        image: require('@/assets/images/content/roof/pannen.png'),
      }, {
        value: 'leien',
        label: t('step_roof_leien_label'),
        image: require('@/assets/images/content/roof/leien.png'),
      }, {
        value: 'tegelpannen',
        label: t('step_roof_tegelpannen_label'),
        image: require('@/assets/images/content/roof/tegelpannen.png'),
      }, {
        value: 'platdak',
        label: t('step_roof_platdak_label'),
        image: require('@/assets/images/content/roof/plat-dak.png'),
      }, {
        value: 'ander',
        label: t('step_roof_ander_label'),
        image: require('@/assets/images/content/roof/ander.png'),
      }],
    },
    validationSchema: yup.object({
      type_roofing: yup.string().required(t('form_field_required')),
    }),
  },
}, {
  id: 'building_height',
  group: 'my-home',
  title: t('step_height_title'),
  icon: '@/assets/icons/lightning.svg',
  content: {
    component: shallowRef(StepHeight),
    payload: {
      options: [{
        value: '1',
        label: t('step_height_1e_verdiep_label'),
        icon: require('@/assets/images/icons/verdiep_1.svg'),
      }, {
        value: '2',
        label: t('step_height_2e_verdiep_label'),
        icon: require('@/assets/images/icons/verdiep_2.svg'),
      }, {
        value: '3',
        label: t('step_height_3e_verdiep_label'),
        icon: require('@/assets/images/icons/verdiep_3.svg'),
      }],
    },
    validationSchema: yup.object({
      building_height: yup.string().required(t('form_field_required')),
    }),
  },
}, {
  id: 'roof_orientation',
  group: 'my-home',
  title: t('step_orientation_title'),
  icon: '@/assets/icons/lightning.svg',
  content: {
    component: shallowRef(StepOrientation),
    payload: {
      options: [{
        value: 'zuid',
        label: t('step_orientation_zuid_label'),
        icon: require('@/assets/images/icons/orientation_zuid.svg'),
      }, {
        value: 'oost',
        label: t('step_orientation_oost_label'),
        icon: require('@/assets/images/icons/orientation_oost.svg'),
      }, {
        value: 'west',
        label: t('step_orientation_west_label'),
        icon: require('@/assets/images/icons/orientation_west.svg'),
      }, {
        value: 'zuidwest',
        label: t('step_orientation_zuidwest_label'),
        icon: require('@/assets/images/icons/orientation_zuidwest.svg'),
      }],
    },
    validationSchema: yup.object({
      roof_orientation: yup.string().required(t('form_field_required')),
    }),
  },
}, {
  id: 'house_type_age',
  group: 'my-home',
  title: t('step_build_title'),
  icon: '@/assets/icons/lightning.svg',
  content: {
    component: shallowRef(StepBuild),
    payload: {
      options: [{
        value: 'nieuwbouw',
        label: t('step_build_nieuwbouw_label'),
        resultLabel: t('step_build_nieuwbouw_result_label'),
        image: require('@/assets/images/content/build_type/nieuwbouw.jpeg'),
      }, {
        value: 'bestaand',
        label: t('step_build_bestaand_label'),
        resultLabel: t('step_build_bestaand_result_label'),
        image: require('@/assets/images/content/build_type/bestaand.jpeg'),
      }],
    },
    validationSchema: yup.object({
      house_type_age: yup.string().required(t('form_field_required')),
    }),
  },
}, {
  id: 'connection',
  group: 'my-home',
  title: t('step_connection_title'),
  icon: '@/assets/icons/lightning.svg',
  content: {
    component: shallowRef(StepConnection),
    payload: {
      options: [{
        value: '3_fasig',
        label: t('step_connection_3_fasig_label'),
        resultLabel: t('step_connection_3_fasig_result_label'),
      }, {
        value: 'mono',
        label: t('step_connection_mono_label'),
        resultLabel: t('step_connection_mono_result_label'),
      }, {
        value: 'unknown',
        label: t('step_connection_unknown_label'),
      }],
    },
    validationSchema: yup.object({
      connection: yup.string().required(t('form_field_required')),
    }),
  },
}, {
  id: 'house_age',
  group: 'my-home',
  title: t('step_vat_title'),
  icon: '@/assets/icons/lightning.svg',
  content: {
    component: shallowRef(StepVat),
    payload: {
      options: [{
        value: 'yes',
        label: t('step_vat_yes_label'),
        icon: require('@/assets/images/icons/yes.svg'),
      }, {
        value: 'no',
        label: t('step_vat_no_label'),
        icon: require('@/assets/images/icons/no.svg'),
      }],
    },
    validationSchema: yup.object({
      house_age: yup.string().required(t('form_field_required')),
    }),
  },
}, {
  id: 'panel_type',
  group: 'my-setup',
  title: t('step_solar_panel_title'),
  icon: '@/assets/icons/lightning.svg',
  content: {
    component: shallowRef(StepSolarPanel),
    payload: {
      options: [{
        value: '430',
        label: t('step_solar_panel_mono_label'),
        resultLabel: t('step_solar_panel_mono_result_label'),
        icon: require('@/assets/images/content/solar_panels/mono.jpg'),
        mediaSizeClass: 'h-48 w-auto',
        mediaWrapperSizeClass: 'h-56',
      }, {
        value: '435',
        label: t('step_solar_panel_polycrystalline_label'),
        resultLabel: t('step_solar_panel_polycrystalline_result_label'),
        icon: require('@/assets/images/content/solar_panels/polycrystalline.jpg'),
        mediaSizeClass: 'h-48 w-auto',
        mediaWrapperSizeClass: 'h-56',
      }],
    },
    validationSchema: yup.object({
      panel_type: yup.string().required(t('form_field_required')),
    }),
  },
}, {
  id: 'battery_pack',
  group: 'my-setup',
  title: t('step_battery_pack_title'),
  icon: '@/assets/icons/lightning.svg',
  content: {
    component: shallowRef(StepBatteryPack),
    payload: {
      options: [{
        value: 'yes',
        label: t('step_battery_pack_yes_label'),
        icon: require('@/assets/images/icons/yes.svg'),
      }, {
        value: 'no',
        label: t('step_battery_pack_no_label'),
        icon: require('@/assets/images/icons/no.svg'),
      }],
    },
    validationSchema: yup.object({
      battery_pack: yup.string().required(t('form_field_required')),
    }),
  },
}, {
  id: 'loading_dock',
  group: 'my-setup',
  title: t('step_charger_title'),
  icon: '@/assets/icons/lightning.svg',
  content: {
    component: shallowRef(StepCharger),
    payload: {
      options: [{
        value: 'yes',
        label: t('step_charger_yes_label'),
        icon: require('@/assets/images/icons/yes.svg'),
      }, {
        value: 'no',
        label: t('step_charger_no_label'),
        icon: require('@/assets/images/icons/no.svg'),
      }],
    },
    validationSchema: yup.object({
      loading_dock: yup.string().required(t('form_field_required')),
    }),
  },
}, {
  id: 'user-info',
  group: 'my-quote',
  title: t('step_info_title'),
  icon: '@/assets/icons/lightning.svg',
  content: {
    component: shallowRef(StepInfo),
    validationSchema: yup.object({
      'email': yup.string().email().required(t('form_field_required')),
      'first_name': yup.string().required(t('form_field_required')),
      'last_name': yup.string().required(t('form_field_required')),
      'street': yup.string().required(t('form_field_required')),
      'house_number': yup.string().required(t('form_field_required')),
      'zip': yup.string().required(t('form_field_required')),
      'city': yup.string().required(t('form_field_required')),
      // 'country': yup.string().required(t('form_field_required')),
      'phone': yup.string().required(t('form_field_required')),
      'terms_and_conditions': yup.bool().required(t('form_field_required')).oneOf([true], t('form_field_required')),
      'newsletter': yup.bool(),
      'comments': yup.string(),
    }),
  },
}];

export default steps;
