<template>
  <div>
    <Field v-if="type !== 'checkbox' && type !== 'radio'" :name="name" v-slot="{ field, errors, errorMessage }">
      <div class="c-form-item" :class="errors.length ? 'c-form-item--error' : ''">
        <label
          v-if="!hideLabel"
          class="c-form-item__label"
          :class="!label || label === '' ? 'c-form-item__label--hidden' : ''"
        >
          {{ label }}
        </label>
        <slot>
          <textarea v-if="type === 'textarea'"
            class="c-form-item__input c-form-item__textarea"
            :placeholder="placeholder"
            :disabled="disabled"
            v-bind="field"
          />
          <input v-else
                 class="c-form-item__input"
                 :placeholder="placeholder"
                 :type="type"
                 :disabled="disabled"
                 v-bind="field"
          />
        </slot>
        <span class="c-form-item__description" v-if="description" v-text="description" />
        <span class="c-form-item__error" :class="errors.length ? 'c-form-item__error--show' : ''">{{ errorMessage }}</span>
      </div>
    </Field>
    <Field v-else :name="name" :type="type" :unchecked-value="false" :value="true" v-slot="{ field, errors, errorMessage }">
      <div class="c-form-item" :class="errors.length ? 'c-form-item--error' : ''">
        <slot>
          <label class="c-form-item__input-wrapper">
            <input class="c-form-item__input" :name="name" :type="type" v-bind="field" :value="true" />
              <span class="c-form-item__input-label">
                <slot name="inputLabel">
                  <span v-html="label" />
                </slot>
              </span>
          </label>
        </slot>
        <span v-if="description" v-text="description" />
        <span class="c-form-item__error" :class="errors.length ? 'c-form-item__error--show' : ''">{{ errorMessage }}</span>
      </div>
    </Field>
  </div>
</template>

<script>
  import { Field } from "vee-validate";

  export default {
    props: {
      label: {
        type: String,
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
      },
      type: {
        type: String,
        default: 'text',
      },
      name: {
        type: String,
        required: true,
      },
      value: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      description: {
        type: String,
        default: '',
      },
    },
    components: {
      Field,
    },
  };
</script>
