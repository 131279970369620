import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full sm-landscape:h-auto w-full relative overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!
  const _component_Start = _resolveComponent("Start")!
  const _component_Simulation = _resolveComponent("Simulation")!
  const _component_Result = _resolveComponent("Result")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Modal),
    (!$setup.simulationStarted)
      ? (_openBlock(), _createBlock(_component_Start, { key: 0 }))
      : (!$setup.simulationEnded)
        ? (_openBlock(), _createBlock(_component_Simulation, { key: 1 }))
        : (_openBlock(), _createBlock(_component_Result, { key: 2 }))
  ]))
}