
  import { watch } from 'vue';
  import { useToast } from "vue-toastification";
  import Start from '@/views/Start.vue';
  import Simulation from '@/views/Simulation.vue';
  import Result from '@/views/Result.vue';
  import Modal from '@/modules/modal/components/Modal.vue';

  import useSimulation from '@/modules/simulation/composables/useSimulation';
  import { t } from '@/utils/languages';

  export default {
    components: {
      Start,
      Simulation,
      Result,
      Modal,
    },
    setup() {
      const toast = useToast();
      const {
        reset: resetSimulation,
        started: simulationStarted,
        ended: simulationEnded,
      } = useSimulation();


      // Functionality to reset the simulation after x minutes of inactivity
      let interactionTimeout: ReturnType<typeof setTimeout>;
      let warningTimeout: ReturnType<typeof setTimeout>;
      let warningToast: ReturnType<any>;
      const timeout = 180000;

      const clearInteractionTimeout = () => {
        if (interactionTimeout) clearTimeout(interactionTimeout);
        if (warningTimeout) {
          toast.dismiss(warningToast);
          clearTimeout(warningTimeout);
        }
      };

      const setInteractionTimeout = () => {
        clearInteractionTimeout();

        interactionTimeout = setTimeout(() => {
          resetSimulation();
          toast.info(t('toast_reset_inactive'));
        }, timeout);

        warningTimeout = setTimeout(() => {
          warningToast = toast.warning(t('toast_almost_inactive'), {
            hideProgressBar: false,
            timeout: timeout / 3,
          });
        }, timeout / 3);
      };

      watch(() => [simulationStarted.value, simulationEnded.value], ([started, ended]) => {
        if (started && !ended) {
          setInteractionTimeout();
          document.addEventListener('mousemove', setInteractionTimeout, false);
          document.addEventListener('mousedown', setInteractionTimeout, false);
          document.addEventListener('keypress', setInteractionTimeout, false);
          document.addEventListener('touchmove', setInteractionTimeout, false);
        } else if (ended || !started) {
          clearInteractionTimeout();
          document.removeEventListener('mousemove', setInteractionTimeout);
          document.removeEventListener('mousedown', setInteractionTimeout);
          document.removeEventListener('keypress', setInteractionTimeout);
          document.removeEventListener('touchmove', setInteractionTimeout);
        }
      });

      return {
        simulationStarted,
        simulationEnded,
      };
    },
  };
