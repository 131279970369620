import { createApp } from 'vue';
import Toast, { POSITION } from "vue-toastification";
import App from '@/App.vue';

import { initializeAxios } from '@/utils/helpers/api';
import { storeCampaignParameter } from '@/utils/helpers/campaign';
import { i18n } from '@/utils/languages';

import '@/assets/css/main.css';
import "vue-toastification/dist/index.css";

// App
const app = createApp(App);

// Initialize axios
initializeAxios();

// Store the campaign from the url so that is doesn't disappear
storeCampaignParameter();

app
  .use(i18n)
  .use(Toast, {
    position: POSITION.TOP_CENTER,
    transition: 'Vue-Toastification__fade',
    hideProgressBar: true,
    containerClassName: "c-toast-wrapper",
    toastClassName: "c-toast",
    bodyClassName: ["c-toast-body"],
  })
  .mount('#app');
