
  import { ref, PropType, onMounted } from 'vue';
  import { Field, ErrorMessage } from 'vee-validate';
  import * as noUiSlider from 'nouislider';
  import 'nouislider/dist/nouislider.css';

  import { t } from '@/utils/languages';
  import { Step } from '@/utils/types/Step';

  export default {
    props: {
      step: {
        type: Object as PropType<Step>,
        required: true,
      },
    },
    components: {
      Field,
      ErrorMessage,
    },
    setup(props) {
      const usageValue = ref(props.step.value);
      const handleOffset = ref(0);

      onMounted(() => {
        const sliderElem = document.getElementById('slider');

        if (!sliderElem) return;
        if (sliderElem.classList.contains('noUi-target')) sliderElem.innerHTML = '';

        const { min, max, step } = props.step.content.payload.slider;
        const slider = noUiSlider.create(sliderElem, {
          start: usageValue.value,
          tooltips: [{
            to: (value) => {
              return Math.round(value) + t('step_usage_eenheid_label');
            },
            from (value) {
              return parseInt(value.replace(t('step_usage_eenheid_label'), ''));
            },
          }],
          step,
          range: {
            min,
            max,
          },
          format: {
            to: (value) => {
              return Math.round(value);
            },
            from (value) {
              return parseInt(value);
            },
          },
        });

        slider.on('update', (values) => {
          [usageValue.value] = values;
        });


        // Calculate offset for arrow
        const sliderHandle = sliderElem.querySelector('.noUi-handle');

        if (!sliderHandle) return;

        handleOffset.value = sliderHandle.getBoundingClientRect().left - sliderElem.getBoundingClientRect().left;
      });

      return {
        usageValue,
        handleOffset,
      };
    },
  };
