
  import { computed, ref } from 'vue';
  import { useForm, ErrorMessage } from 'vee-validate';
  import { useToast } from "vue-toastification";

  import useSimulation from '@/modules/simulation/composables/useSimulation';
  import usePricing from '@/modules/pricing/composables/usePricing';
  import { t } from '@/utils/languages';

  export default {
    components: {
      ErrorMessage,
    },
    setup() {
      const loading = ref(false);
      const {
        formData,
        result,
        currentStep,
        steps,
        isFirstStep,
        isLastStep,
        incrementStep,
        decrementStep,
        storeSimulationData,
        ended,
      } = useSimulation();

      const { calculate } = usePricing();

      const { handleSubmit, errors } = useForm({
        // @ts-ignore
        validationSchema: computed(() => currentStep.value.content.validationSchema),
      });

      const onInvalidSubmit = () => {
        const errorElem = document.querySelector('.c-form-item--error');

        errorElem?.scrollIntoView();
      };

      const onSubmit = handleSubmit(async (values) => {
        formData.value = { ...formData.value, ...values };

        if (!isLastStep.value) {
          incrementStep();
        } else {
          try {
            loading.value = true;
            result.value = await calculate();
            await storeSimulationData();
            ended.value = true;
            loading.value = false;
            window.scrollTo(0, 0);
          } catch (error) {
            console.log(error);
            const toast = useToast();
            toast.warning(t('toast_api_save_error'));
            loading.value = false;
          }
        }
      }, onInvalidSubmit);

      return {
        loading,
        steps,
        errors,
        currentStep,
        isFirstStep,
        isLastStep,
        onSubmit,
        incrementStep,
        decrementStep,
      };
    },
  };
