import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex border-t border-light lg:border-0 justify-center items-stretch flex-shrink-0 py-5 lg:pb-0 px-6 sm:px-10 md:px-16 lg:pl-28 lg:pr-20 xl:pl-40 xl:pr-28" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    class: "flex flex-col h-full overflow-hidden lg:pb-12",
    onSubmit: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.onSubmit && $setup.onSubmit(...args)))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.steps, (step) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(step.content.component), {
        key: step.id,
        class: _normalizeClass(["overflow-y-scroll overflow-x-hidden px-6 sm:px-10 md:px-16 lg:pl-28 lg:pr-20 xl:pl-40 xl:pr-28", { 'hidden': $setup.currentStep.id !== step.id }]),
        step: step,
        onSelecting: _cache[0] || (_cache[0] = ($event: any) => ($setup.loading = $event))
      }, null, 8, ["class", "step"]))
    }), 128)),
    _createElementVNode("div", _hoisted_1, [
      (!$setup.isFirstStep)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "cursor-pointer mr-4 xs:mr-8 sm:mr-16 text-sm sm:text-base flex items-center",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.decrementStep && $setup.decrementStep(...args)))
          }, [
            _createElementVNode("img", {
              class: "mr-3 h-3 sm:h-4 hidden xs:block",
              src: require('@/assets/images/icons/arrow_left.svg'),
              alt: "Previous step"
            }, null, 8, _hoisted_2),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('simulation_form_previous_button')), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        disabled: !!$setup.errors[$setup.currentStep.id] || $setup.loading,
        type: "submit",
        class: "c-button c-button--primary c-button--responsive"
      }, [
        _createTextVNode(_toDisplayString(!$setup.isLastStep ? _ctx.$t('simulation_form_next_button') : _ctx.$t('simulation_form_submit_button')) + " ", 1),
        (!$setup.isLastStep)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "ml-3 h-3 sm:h-4 hidden xs:block",
              src: require('@/assets/images/icons/arrow_right.svg'),
              alt: "Next step"
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true)
      ], 8, _hoisted_3)
    ])
  ], 32))
}