
  import { ref } from 'vue';
  import { Field } from "vee-validate";

  export default {
    props: {
      name: {
        type: String,
        required: true,
      },
      options: {
        type: Array,
        required: true,
      },
      itemsPerRow: {
        type: Number,
        default: 3,
        max: 3,
        min: 2,
      },
    },
    components: {
      Field,
    },
    emits: ['select', 'selecting'],
    setup(props, { emit }) {
      const isSelecting = ref(false);
      const selectValue = (value) => {
        if (!isSelecting.value) {
          isSelecting.value = true;
          emit('selecting', isSelecting.value);

          setTimeout(() => {
            emit('select', value);
            isSelecting.value = false;
            emit('selecting', isSelecting.value);
          }, 200);
        }
      };

      return {
        selectValue,
      };
    },
  };
