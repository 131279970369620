import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full mt-3" }
const _hoisted_2 = { class: "flex w-full" }
const _hoisted_3 = { class: "lg:flex w-full" }
const _hoisted_4 = { class: "flex lg:w-1/2" }
const _hoisted_5 = { class: "flex lg:w-1/2" }
const _hoisted_6 = { class: "lg:flex w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormItem = _resolveComponent("FormItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormItem, {
      name: "email",
      type: "email",
      label: _ctx.$t('step_info_email_label'),
      placeholder: _ctx.$t('step_info_email_placeholder')
    }, null, 8, ["label", "placeholder"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormItem, {
        class: "w-1/2 pr-3 lg:pr-6",
        name: "first_name",
        type: "text",
        label: _ctx.$t('step_info_first_name_label'),
        placeholder: _ctx.$t('step_info_first_name_placeholder')
      }, null, 8, ["label", "placeholder"]),
      _createVNode(_component_FormItem, {
        class: "w-1/2",
        name: "last_name",
        type: "text",
        label: _ctx.$t('step_info_last_name_label'),
        placeholder: _ctx.$t('step_info_last_name_placeholder')
      }, null, 8, ["label", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_FormItem, {
          class: "w-8/12 pr-3",
          name: "street",
          type: "text",
          label: _ctx.$t('step_info_street_label'),
          placeholder: _ctx.$t('step_info_street_placeholder')
        }, null, 8, ["label", "placeholder"]),
        _createVNode(_component_FormItem, {
          class: "w-4/12 lg:pr-6",
          name: "house_number",
          type: "text",
          label: _ctx.$t('step_info_streetnumber_label'),
          placeholder: _ctx.$t('step_info_streetnumber_placeholder')
        }, null, 8, ["label", "placeholder"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_FormItem, {
          class: "w-4/12 pr-3",
          name: "zip",
          type: "text",
          label: _ctx.$t('step_info_cityzip_label'),
          placeholder: _ctx.$t('step_info_cityzip_placeholder')
        }, null, 8, ["label", "placeholder"]),
        _createVNode(_component_FormItem, {
          class: "w-8/12",
          name: "city",
          type: "text",
          label: _ctx.$t('step_info_city_label'),
          placeholder: _ctx.$t('step_info_city_placeholder')
        }, null, 8, ["label", "placeholder"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_FormItem, {
        class: "lg:w-1/2 lg:pr-6",
        name: "country",
        type: "text",
        label: _ctx.$t('step_info_country_label'),
        placeholder: _ctx.$t('step_info_country_placeholder'),
        disabled: true,
        description: _ctx.$t('step_info_country_description')
      }, null, 8, ["label", "placeholder", "description"]),
      _createVNode(_component_FormItem, {
        class: "lg:w-1/2",
        name: "phone",
        type: "text",
        label: _ctx.$t('step_info_phone_label'),
        placeholder: _ctx.$t('step_info_phone_placeholder')
      }, null, 8, ["label", "placeholder"])
    ]),
    _createVNode(_component_FormItem, {
      name: "comments",
      type: "textarea",
      "hide-label": true,
      placeholder: _ctx.$t('step_info_comments_label')
    }, null, 8, ["placeholder"]),
    _createVNode(_component_FormItem, {
      name: "newsletter",
      type: "checkbox",
      label: _ctx.$t('step_info_newsletter_label')
    }, null, 8, ["label"]),
    _createVNode(_component_FormItem, {
      name: "terms_and_conditions",
      type: "checkbox",
      label: `${_ctx.$t('step_info_terms_conditions_label')} ${_ctx.$t('step_info_terms_conditions_label_link_text')}`
    }, null, 8, ["label"])
  ]))
}