
export const storeCampaignParameter = () => {
  const urlParams = new URLSearchParams(document.location.search);
  const campaignInUrl = urlParams.get('src');
  const campaignInStorage = localStorage.getItem('campaign');

  if ((campaignInUrl && !campaignInStorage) || (campaignInUrl && campaignInStorage && campaignInUrl !== campaignInStorage)) {
    localStorage.setItem('campaign', campaignInUrl);
  } else if (!campaignInUrl && campaignInStorage) {
    urlParams.set('src', campaignInStorage);
    window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
  }
};

export const getCampaign = () => {
  const urlParams = new URLSearchParams(document.location.search);

  return urlParams.get('src');
};
