<template>
  <div class="flex flex-col h-full overflow-hidden">
    <div class="overflow-y-scroll overflow-x-hidden lg:pb-12">
      <div class="px-6 py-6 lg:pt-12 sm:px-10 md:px-16 xl:pt-20 lg:pl-28 lg:pr-20 xl:pl-40 xl:pr-28">
        <div class="sm:grid sm:grid-cols-12 sm:gap-x-9 mb-12">
          <div class="col-span-12 sm:col-span-6 mb-6 sm:mb-0 text-base md:text-lg leading-8">
            <h2 class="font-bold mb-2.5">{{ $t('result_title_home') }}</h2>
            <div class="flex">
              <span class="w-7/12 pr-3">{{ $t('result_usage_label') }}</span>
              <span class="w-5/12">{{ formData['yearly_kw'] }}{{ $t('step_usage_eenheid_label') }}</span>
            </div>
            <div class="flex">
              <span class="w-7/12 pr-3">{{ $t('result_roof_label') }}</span>
              <span class="w-5/12">{{ getStepLabel('type_roofing') }}</span>
            </div>
            <div class="flex">
              <span class="w-7/12 pr-3">{{ $t('result_height_label') }}</span>
              <span class="w-5/12">{{ getStepLabel('building_height') }}</span>
            </div>
            <div class="flex">
              <span class="w-7/12 pr-3">{{ $t('result_orientation_label') }}</span>
              <span class="w-5/12">{{ getStepLabel('roof_orientation') }}</span>
            </div>
            <div class="flex">
              <span class="w-7/12 pr-3">{{ $t('result_build_label') }}</span>
              <span class="w-5/12">{{ getStepLabel('house_type_age') }}</span>
            </div>
            <div class="flex">
              <span class="w-7/12 pr-3">{{ $t('result_connection_label') }}</span>
              <span class="w-5/12">{{ getStepLabel('connection') }}</span>
            </div>
            <div class="flex">
              <span class="w-7/12 pr-3">{{ $t('result_vat_label') }}</span>
              <span class="w-5/12">{{ getStepLabel('house_age') }}</span>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-6 flex flex-col text-base md:text-lg leading-8">
            <h2 class="font-bold mb-2.5">{{ $t('result_title_installation') }}</h2>
            <div class="flex">
              <span class="w-7/12 pr-3">{{ $t('result_solar_panel_label') }}</span>
              <span class="w-5/12">{{ getStepLabel('panel_type') }}</span>
            </div>
            <div class="flex">
              <span class="w-7/12 pr-3">{{ $t('result_battery_pack_label') }}</span>
              <span class="w-5/12">{{ getStepLabel('battery_pack') }}</span>
            </div>
            <div class="flex">
              <span class="w-7/12 pr-3">{{ $t('result_charger_label') }}</span>
              <span class="w-5/12">{{ getStepLabel('loading_dock') }}</span>
            </div>
            <div class="flex mt-auto">
              <span class="w-7/12 pr-3">{{ $t('result_reference_label') }}</span>
              <span class="w-5/12">#{{ formId }}</span>
            </div>
          </div>
        </div>

        <div>
          <div class="flex justify-start items-center relative">
            <div class="h-px w-full bg-primary absolute top-1/2" />
            <div
              v-for="(tab, index) in calculationTabs"
              :key="tab.value"
              class="text-sm h-8 flex-grow sm:w-36 sm:flex-grow-0 px-4 relative flex items-center justify-center text-center leading-none cursor-pointer border-t border-b border-r border-primary bg-white"
              :class="{
                'rounded-l-full border-l': index === 0,
                'rounded-r-full': index === calculationTabs.length - 1,
                'bg-primary font-medium': tab.value === activeCalculationTab,
              }"
              @click="activeCalculationTab = tab.value"
            >
              {{ tab.label }}
            </div>
          </div>
          <div v-if="activeCalculationTab === 'overview'" class="sm:grid sm:grid-cols-12 sm:gap-x-9">
            <div class="col-span-12 sm:col-span-6 py-6 sm:my-6 sm:py-0 border-b sm:border-r sm:border-b-0 border-primary flex flex-col">
              <h2 class="text-base md:text-lg leading-8 font-bold">{{ $t('result_total_label') }}</h2>
              <span class="font-serif text-3xl mt-1">{{ formatPrice(result.totalWithBonuses) }}</span>
              <span class="text-xs uppercase">{{ $t('result_total_vat', { vat: formData['house_age'] === 'yes' ? '6%' : '21%' }) }}</span>
            </div>
            <div class="col-span-12 sm:col-span-6 py-6 sm:my-6 sm:py-0 flex flex-col">
              <h2 class="text-base md:text-lg leading-8 font-bold">{{ $t('result_time_label') }}</h2>
              <div class="flex items-center">
                <div class="flex flex-col mr-7 flex-shrink-0">
                  <span class="font-serif text-3xl mt-1">{{ formatNumber(result.returnTime) }}</span>
                  <span class="text-xs uppercase">{{ $t('result_time_year') }}</span>
                </div>
                <p>{{ $t('result_time_text', { amount: formatNumber(result.returnTime) }) }}</p>
              </div>
            </div>
          </div>
          <div v-else-if="activeCalculationTab === 'detail'" class="sm:grid sm:grid-cols-12 sm:gap-x-9">
            <div class="col-span-12 xl:col-span-10 py-6 sm:my-6 text-base md:text-lg leading-8">
              <h2 class="font-bold mb-2.5">{{ $t('result_detail_your_investment_label') }}</h2>
              <div class="flex justify-between">
                <span class="pr-6">{{ $t('result_detail_solar_panels_label') }}</span>
                <span class="font-serif font-bold flex-shrink-0">{{ formatPrice(result.totalPanelPrice) }}</span>
              </div>
              <div class="flex justify-between">
                <span class="pr-6">{{ $t('result_detail_charger_label') }}</span>
                <span class="font-serif font-bold flex-shrink-0">{{ formatPrice(result.chargerPriceWithVat) }}</span>
              </div>
              <div class="flex justify-between">
                <span class="pr-6">{{ $t('result_detail_battery_pack_label') }}</span>
                <span class="font-serif font-bold flex-shrink-0">{{ formatPrice(result.batteryPriceWithVat) }}</span>
              </div>

              <h2 class="font-bold mt-6 mb-2.5">{{ $t('result_detail_bonuses_label') }}</h2>
              <div class="flex justify-between">
                <span class="pr-6">{{ $t('result_detail_bonus_solar_panels_label') }}</span>
                <span class="font-serif font-bold flex-shrink-0">{{ formatPrice(result.panelBonus) }}</span>
              </div>
              <div class="flex justify-between">
                <span class="pr-6">{{ $t('result_detail_bonus_battery_pack_label') }}</span>
                <span class="font-serif font-bold flex-shrink-0">{{ formatPrice(result.batteryBonus) }}</span>
              </div>

              <h2 class="font-bold mt-6 mb-2.5">{{ $t('result_detail_roi_time_label') }}</h2>
              <div class="flex justify-between">
                <span class="pr-6">{{ $t('result_detail_roi_label') }}</span>
                <span class="font-serif font-bold flex-shrink-0">{{ formatNumber(result.returnTime) }} <span class="lowercase">{{ $t('result_time_year') }}</span></span>
              </div>

              <div class="flex justify-between mt-6">
                <span class="pr-6 font-bold">{{ $t('result_detail_total_label') }}</span>
                <span class="font-serif font-bold flex-shrink-0">{{ formatPrice(result.totalWithBonuses) }}</span>
              </div>
            </div>
          </div>
        </div>
        <p class="text-xs mt-4">{{ $t('result_total_comment') }}</p>
      </div>

      <div class="flex border-t border-light lg:border-0 justify-center items-stretch flex-shrink-0 py-5 lg:pb-0 px-6 sm:px-10 md:px-16 lg:pl-28 lg:pr-20 xl:pl-40 xl:pr-28">
        <button class="c-button c-button--primary c-button--responsive" @click="reset">
          {{ $t('result_new_simulation_button') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue';
  import useSimulation from '@/modules/simulation/composables/useSimulation';
  import { t } from '@/utils/languages';

  export default {
    setup() {
      const calculationTabs = ref([{
        value: 'overview',
        label: t('result_tab_overview'),
      }, {
        value: 'detail',
        label: t('result_tab_detail'),
      }]);
      const activeCalculationTab = ref('overview');

      const {
        formId,
        formData,
        result,
        reset,
        getStepLabel,
      } = useSimulation();

      const formatPrice = (price) => {
        return new Intl.NumberFormat('nl-BE', { style: 'currency', currency: 'EUR' }).format(price);
      };

      const formatNumber = (number) => {
        return new Intl.NumberFormat('nl-BE', { style: 'decimal', maximumFractionDigits: 1 }).format(number);
      };

      return {
        formId,
        formData,
        getStepLabel,
        formatPrice,
        formatNumber,
        result,
        reset,
        calculationTabs,
        activeCalculationTab,
      };
    },
  };
</script>
